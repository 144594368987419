
/* eslint-disable @typescript-eslint/camelcase */
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import NotificationCommodityGrouping from "@/app/ui/components/notification-commodity-group/index.vue";
import debounce from "lodash/debounce";
import { BaggingController } from "@/app/ui/controllers/BaggingController";
import {
  BaggingData,
  PayloadCreateBagging,
  RegionBaggingGrouping,
  SttNumberDetail
} from "@/domain/entities/Bagging";
import {
  storeDatatoLocalStorage,
  removeDataLocalStorage
} from "@/app/infrastructures/misc/Utils";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import convertDecimal from "@/app/infrastructures/misc/common-library/ConvertDecimal";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import isMigrateFromElexys from "@/app/infrastructures/misc/common-library/IsMigrateFromElexys";
import Print from "../../modules/print.vue";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import Checkbox from "primevue/checkbox";
import { flags } from "@/feature-flags";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import { TrackingSttData, TrackingSttDetailData } from "@/domain/entities/TrackingStt";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import isFormatLiloBag from "@/app/infrastructures/misc/common-library/isFormatLiloBag";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    InputDimension,
    InputAdornment,
    Print,
    NotificationCommodityGrouping,
    Checkbox
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.setOpenSuccess(false);
      this.isPartial = false;
      next();
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class CreateFormBaggingManifest extends mixins(OutGoingMixins) {
  alertDangerousGoods = false;
  formatPrice = formatPrice;
  isErrorBagging = false;
  notificationTimeout?: NodeJS.Timeout = undefined;

  get isShowRTC() {
    return flags.feature_rtc_bagging_enabled.isEnabled();
  }

  get bagChargeableWeight() {
    const weight = Number((this.form.weight as string).replace(',','.'))
    return weight > this.totalBagVolumeWeight
      ? this.isValueDecimal(weight)
      : this.isValueDecimal(+this.totalBagVolumeWeight)
  }

  get labelToggleRTC() {
    return {
      text: this.form.isRTC ? 'Siap Dikargo' : 'Belum Siap Dikargo',
      style: this.form.isRTC
        ? 'bg-green-lp-300 text-green-lp-400'
        : 'bg-orange-lp-100 text-orange-lp-200'
    }
  }

  pagination = {
    page: 1,
    limit: 50
  };
  mounted() {
    this.fetchDestinationCity("");
    AccountController.checkAccountLocation();
    BaggingController.setResetCreateBagging();
    this.refs = this.$refs;
    if (this.dataSttToBeCreate.length)
      this.autoFillDestination(this.dataSttToBeCreate[0]);
  }
  refs: any = "";

  // delete stt pieces
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  sttNumberListTrash: string[] = [];
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.sttNumberToDelete = this.sttNumberToDelete.split("#")[0];
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      // find index no. stt
      const indexSttNumber = this.dataSttToBeCreate.findIndex((data: any) => {
        return (
          data.sttNo === this.sttNumberToDelete ||
          data.sttNumberElexys === this.sttNumberToDelete
        );
      });

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = this.$t("No. STT tidak ditemukan/sudah terhapus");
      } else {
        if (this.dataSttToBeCreate.length === 1) {
          this.form.destinationCity = "";
        }
        this.dataSttToBeCreate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        const totalGrossWeight = (Math.ceil(this.totalGrossWeight * 100)/100).toFixed(2).replace('.',',');
        this.form.weight = totalGrossWeight
        // store bagging gross weight to local storage
        storeDatatoLocalStorage("baggingGrossWeight", totalGrossWeight);
        this.sttNumberToDelete = "";
        dataLayer(
          "bagging_delete_STT",
          {
            success_message: "STT berhasil dihapus"
          },
          []
        );
      }
    }
    this.isUnpaid = false;
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt?.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
    this.isUnpaid = false;
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }
  get typeUser() {
    return this.dataProfile.account_type_detail.type === "console"
      ? "Consolidator"
      : "Sub-Consolidator";
  }
  get baggingDate() {
    return new Date();
  }

  // handle stt pieces
  errorSttNumber = "";
  isErrorTyping = false;
  isPartial = false;
  countFailed = 0;
  countSuccess = 0;
  isScan = false;

  addScanSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.isScan = true;

      this.addSttToCreate();
    }
  }

  async addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.isScan = false;
      await this.addSttToCreate();
    }
  }

  checkIsSTTOrBag(val: string) {
    return !!val.trim().match(/^\d{1,2}LP\w+$/g) || !!val.trim().match(/^\w{1,3}-\w+$/g);
  }

  async handleScanOthers(str: string) {
    MainAppController.showLoading();
    try {
      const resp = await TrackingController.trackStt({
        sttNo: str,
        isGetSla: false,
        isSecondCall: false,
        cache: true,
      });
      if (resp) {
        return {
          sttNo: TrackingController.trackingSttData.sttDetail.sttNo,
          noRefExternal: str,
        };
      } else {
        playNotification("error");
        this.errorSttNumber = this.$t('bagging.notFoundSTTBagging');
        this.isErrorTyping = true;
      }
    } finally {
      TrackingController.setTrackingSttData(
        new TrackingSttData(new TrackingSttDetailData(), [])
      );
      MainAppController.closeLoading();
    }
  }

  async handleValidationSTTOthers():Promise<{ sttNo: string; noRefExternal: string; }> {
    let sttNoOthers: any = { sttNo: "", noRefExternal: "" };
    const isSTT = this.checkIsSTTOrBag(this.form.sttNumber);
    if (!isSTT) {
      sttNoOthers = await this.handleScanOthers(this.form.sttNumber);
    }
    return sttNoOthers;
  }

  async addSttToCreate() {
    this.isErrorBagging = false;
    this.errorSttNumber = "";
    const sttNoOthers = await this.handleValidationSTTOthers();
    await BaggingController.getDetailSttNumber({
      sttNumber: !this.checkIsSTTOrBag(this.form.sttNumber) ? sttNoOthers?.sttNo ?? "" : this.form.sttNumber,
      callback: this.addStt,
      noRef: sttNoOthers?.noRefExternal ?? ""
    });
    this.form.sttNumber = this.form.sttNumber.split("#")[0];
  }
  addStt(responseDetailStt: any, noRefExternal: string) {
    this.notification = false;
    const checkErrorUpdate = responseDetailStt.find(
      (item: any) => item.isAllowUpdateStatus === false
    );
    const checkEligibleUpdate = responseDetailStt.map(
      (item: any) => item.isAllowUpdateStatus !== false
    );
    const checkErrorSttNeedAssessment = responseDetailStt.find(
      (item: any) =>
        item.stt.sttAssessmentStatus === "waiting" ||
        item.stt.sttAssessmentStatus === "rejected"
    );
    this.isErrorNotification = false;
    const isNotEligible = (val: any) => val === false;
    if (checkErrorSttNeedAssessment) {
      this.isErrorBagging = true;
      playNotification("error");
      this.isErrorNotification = true;
      this.errorSttNumber =
        "Gagal scan karena adjustment STT belum disetujui tim Lion Parcel, tunggu dihubungi CS.";
      this.messageErrorNotification = "Silahkan menunggu CS follow up untuk konfirmasi adjust ulang STT. ";
      for (const detailSTT of responseDetailStt) {
        this.onFailedSttResults(detailSTT);
      }

      clearTimeout(this.notificationTimeout as NodeJS.Timeout);
      this.notificationTimeout = setTimeout(() => {
        this.notification = false;
      }, 15000);
    } else if (checkEligibleUpdate.every(isNotEligible)) {
      playNotification("error");
      this.isErrorTyping = true;
      this.errorSttNumber = checkErrorUpdate.errorMessage;
    } else {
      for (const detailSTT of responseDetailStt) {
        this.onValidateSttResults(detailSTT, noRefExternal);
      }
      // re focus
      const inputStt: any = this.$refs.inputStt;
      inputStt.focus();

      // remove notification after 15 second
      if (!this.isUnpaid) {
        clearTimeout(this.notificationTimeout as NodeJS.Timeout);
        this.notificationTimeout = setTimeout(() => {
          this.notification = false;
          this.notificationCommodityGrouping = false;
        }, 15000);
      }
    }
  }
  onValidateSttResults(detailSTT: any, noRefExternal:string) {
    const indexCurrentSttNumber = this.dataSttToBeCreate.findIndex(
      (data: any) => {
        return data.sttNo === detailSTT.stt.sttNo;
      }
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.errorSttNumber = this.$t("No. STT sudah di input");
      this.isErrorTyping = true;
      return;
    } else if (!detailSTT.isPaid) {
      this.isUnpaid = true;
      this.isErrorNotification = true;
      this.messageErrorNotification =
        this.$t("Tunggu sampai customers selesai melunasi tagihan kurang bayar");
      this.errorSttNumber =
       this.$t("Terjadi kelebihan berat sehingga status tidak dapat diupdate ke Bagging.");
      playNotification("error");
      dataLayer(
        "input_STT/bagging_failed",
        {
          error_message: this.isScan
            ? "Scan No. STT Gagal"
            : "Tambah No. STT Gagal"
        },
        []
      );
    } else if (
      this.form.destinationCity?.code &&
      this.form.destinationCity?.code !== detailSTT.stt.sttDestinationCityId &&
      this.validationIntraRegion(detailSTT)
    ) {
      this.errorSttNumber =
        this.$t("No. STT tidak sesuai dengan kota tujuan yang dipilih");
      this.isErrorNotification = true;
      this.isDiffDestination = true;
      this.messageErrorNotification =
        this.$t("STT tidak sesuai dengan kota tujuan yang dipilih");
      playNotification("error");
    } else {
      if (detailSTT.isAllowUpdateStatus) {
        detailSTT.stt.sttNoRefExternal = noRefExternal;
        this.dataSttToBeCreate.push({
          ...detailSTT.stt,
          regionBaggingGrouping: detailSTT.regionBaggingGrouping
        });
        if (
          detailSTT.regionBaggingGrouping.baggingRegionOriginId &&
          detailSTT.regionBaggingGrouping.baggingRegionDestinationId
        ) {
          this.autoFillDestination(detailSTT);
        }
        playNotification("success");
        dataLayer(
          "input_STT/bagging_success",
          {
            success_message: "STT berhasil di input"
          },
          []
        );
      }
    }
    this.onSuccessSttResults(detailSTT);
  }
  onSuccessSttResults(detailSTT: any) {
    const destinationCity: any = this.form.destinationCity;
    this.detailSttNotification = {
      sttNumber: detailSTT.stt.sttNo,
      destinationCity: destinationCity.name,
      product: detailSTT.stt.sttProductType,
      woodpacking: detailSTT.stt.sttWoodPacking === "Yes",
      commodity: detailSTT.stt.sttCommodityName,
      totalPieces: detailSTT.stt.sttTotalPiece,
      codAmount: detailSTT.stt.sttCodAmount,
      sttNumberElexys: detailSTT.stt.sttNumberElexys,
      isDangerousGoods: detailSTT.stt.isDangerousGoods,
      cityId: detailSTT.stt.sttDestinationCityId,
      cityName: detailSTT.stt.sttDestinationCityName
    };
    this.form.sttNumber = "";
    this.form.cacheDestinationCity = this.form.destinationCity;
    const totalGrossWeight = (Math.ceil(this.totalGrossWeight * 100)/100).toFixed(2).replace('.',',');
    this.form.weight = totalGrossWeight
    storeDatatoLocalStorage("baggingGrossWeight", totalGrossWeight);

    if (detailSTT.stt.isDangerousGoods) {
      this.alertDangerousGoods = true;
    } else {
      this.notification = true;
    }

    if (this.dataSttToBeCreate.length > 1 && !this.isErrorNotification) {
      if (
        this.dataSttToBeCreate[0].sttCommodityGroupCode !==
        detailSTT.stt.sttCommodityGroupCode
      ) {
        this.detailNotificationCommodityGrouping = {
          sttCommodityGroupName: this.dataSttToBeCreate[0]
            .sttCommodityGroupName,
          message: this.$t("Komoditas group tidak sama")
        };
        this.notificationCommodityGrouping = true;
      } else {
        this.notificationCommodityGrouping = false;
      }
    }
  }

  onFailedSttResults(detailSTT: any) {
    this.detailSttNotification = {
      sttNumber: detailSTT.stt.sttNo,
      destinationCity: `${detailSTT.stt.sttDestinationCityId} - ${detailSTT.stt.sttDestinationCityName}`,
    };

    this.notification = true;
  }

  async autoFillDestination(detailSTT: any) {
    if (!this.form.destinationCity?.code) {
      await LocationController.getCityList({
        search:
          this.dataProfile.account_location.city_code ===
            detailSTT.regionBaggingGrouping.baggingRegionDestinationId ||
          detailSTT.regionBaggingGrouping.baggingRegionOriginId ===
            detailSTT.regionBaggingGrouping.baggingRegionDestinationId
            ? detailSTT.sttDestinationCityId ||
              detailSTT.stt.sttDestinationCityId
            : detailSTT.regionBaggingGrouping.baggingRegionDestinationId,
        status: "",
        page: 1,
        limit: 10
      });
      this.form.destinationCity = this.destinationCityList[0];
      if (!this.regionBaggingGrouping.baggingRegionDestinationId) {
        this.getRegionBaggingGrouping(this.form.destinationCity?.code);
      }
    }
  }

  validationIntraRegion(detailSTT: any) {
    return detailSTT.regionBaggingGrouping.baggingRegionOriginId ===
      detailSTT.regionBaggingGrouping.baggingRegionDestinationId
      ? true
      : this.regionBaggingGrouping.baggingRegionDestinationId !==
          detailSTT.regionBaggingGrouping.baggingRegionDestinationId;
  }

  onCloseNotification() {
    this.isUnpaid = false;
    this.isDiffDestination = false;
    this.errorSttNumber = "";
  }

  messageErrorNotification = "";
  get totalStt() {
    return this.dataSttToBeCreate.length;
  }
  get totalPieces() {
    return this.dataSttToBeCreate.reduce((a: any, b: any) => {
      return a + b.piece.length;
    }, 0);
  }
  get totalGrossWeight() {
    return this.dataSttToBeCreate.reduce((a: any, b: any) => {
      return (
        a +
        b.piece.reduce((c: any, d: any) => {
          return c + d.sttPieceGrossWeight;
        }, 0)
      );
    }, 0);
  }
  get totalVolumeWeight() {
    return this.dataSttToBeCreate.reduce((a: any, b: any) => {
      return (
        a +
        b.piece.reduce((c: any, d: any) => {
          return c + d.sttPieceVolumeWeight;
        }, 0)
      );
    }, 0);
  }

  get totalDangerousGoods() {
    return this.dataSttToBeCreate.filter((stt: any) => stt.isDangerousGoods)
      .length;
  }

  // Bagging
  get totalBagVolumeWeight() {
    const total =
      (this.form.length * this.form.width * this.form.height) / 4000;
    return Math.ceil(total);
  }

  // form model
  form = {
    driverName: "",
    licensePlate: "",
    destinationCity: "" as any,
    cacheDestinationCity: "",
    pickupDate: new Date(),
    sttNumber: "",
    weight: JSON.parse(localStorage.getItem("baggingGrossWeight") ?? "0") || 1,
    length: 30,
    width: 20,
    height: 10,
    isRTC: false
  };

  isUnpaid = false;
  isErrorNotification = false;

  get isDisableAddSttNumber() {
    return !this.form.sttNumber;
  }

  // Origin City
  get originCity() {
    return `${this.dataProfile.account_location.city_code} - ${this.dataProfile.account_location.city_name}`;
  }

  // Destination City
  fetchDestinationCity = debounce((search: string) => {
    if (search.length > 2 || !search) {
      LocationController.getCityList({
        search: search,
        status: "",
        page: 1,
        limit: 10
      });
    }
  }, 250);
  get destinationCityList() {
    return LocationController.cityData.cityData.map(e => ({
      id: e.id,
      name: `${e.code} - ${e.name}`,
      transit: e.originTransit
        ? `${e.destinationCodeTransit} - ${e.destinationTransit}`
        : "-",
      code: e.code
    }));
  }
  get isLoadingDestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  regionBaggingGrouping = new RegionBaggingGrouping();
  loadingRegion = false;

  get showRecommendationRegion() {
    return (
      this.form.destinationCity?.code &&
      !this.loadingRegion &&
      this.showNotesDestination &&
      this.regionBaggingGrouping.baggingRegionDestinationId &&
      this.regionBaggingGrouping.baggingRegionDestinationName &&
      this.form.destinationCity?.code !==
        this.regionBaggingGrouping.baggingRegionDestinationId &&
      this.dataProfile.account_location.city_code !==
        this.regionBaggingGrouping.baggingRegionDestinationId &&
      this.regionBaggingGrouping.baggingRegionOriginId !==
        this.regionBaggingGrouping.baggingRegionDestinationId
    );
  }

  async getRegionBaggingGrouping(code: string) {
    if (code) {
      try {
        this.loadingRegion = true;
        MainAppController.closeErrorMessage();
        this.regionBaggingGrouping = await BaggingController.getRegionBaggingGrouping(
          code
        );
        this.showNotesDestination = true;
      } catch (error) {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Gagal Mendapatkan Region Bagging Grouping",
            () => this.getRegionBaggingGrouping(code)
          )
        );
        this.showNotesDestination = false;
      } finally {
        this.loadingRegion = false;
      }
    }
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalWithComma(value, 2);
  }

  // panel pickup
  chevron = false;
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op?.toggle(event);
    this.chevron = !this.chevron;
  }

  // notification handler
  notification = false;
  detailSttNotification: any = null;
  notificationCommodityGrouping = false;
  detailNotificationCommodityGrouping: any = null;

  // table
  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("bagging") || "[]"
  );
  get dataSttToBeCreate() {
    storeDatatoLocalStorage("bagging", this.sourceSttFromLocalStorage);
    return this.sourceSttFromLocalStorage;
  }
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${item.sttNo}</div>`;
        }
      },
       {
        name: this.$t("No. Referensi"),
        styleHead:  "w-3/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${
            isFormatLiloBag(item.sttNoRefExternal) ? "-" : item.sttNoRefExternal || "-"
          }</div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300 flex">
                    <img src="${require(`@/app/ui/assets/svg/product-logo/${item.sttProductType.toLowerCase()}-active.svg`)}" />
                </div>`;
        }
      },
      {
        name: this.$t("Komoditas"),
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${item.sttCommodityName}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: "Kota tujuan",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttDestinationCityId}
                  </div>
                </div>`;
        }
      },
      {
        name: "Dangerous Goods",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<img src="${require(`@/app/ui/assets/svg/${
            item.isDangerousGoods ? "icon-check-circle-red" : "icon-close-black"
          }.svg`)}" />`;
        }
      },
    ];
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get listSttPieces() {
    return this.dataSttToBeCreate.map((e: any) => {
      return e.sttNo;
    });
  }

  withPrint = false;

  onDelaySave = debounce(()=>{
   this.onSave();
  }, 250)

  onShowLoadingSave() {
    this.handleConfirmationSave(false);
    MainAppController.showLoading();
    this.onSave();
  }

  async onSave() {
    const destination: any = this.form.destinationCity;
    const payload = new PayloadCreateBagging({
      destinationCityCode: destination.code,
      customGrossWeight: Number((this.form.weight as string).replace(',','.')),
      customLength: this.form.length,
      customWidth: this.form.width,
      customHeight: this.form.height,
      productType: this.filterDominanProductAndCommodity(
        this.listSttProduct,
        this.listSttCommodityGroup
      ).product,
      commodityGroupCode: this.filterDominanProductAndCommodity(
        this.listSttProduct,
        this.listSttCommodityGroup
      ).commodity,
      isRtc: this.form.isRTC,
      bagStt: this.listSttPieces,
    });
    const respCreateBagging = await BaggingController.onGenerate(payload);
    if (respCreateBagging.data) {
      if (
        respCreateBagging.data.totalSttFailed > 0 &&
        respCreateBagging.data.totalSttSuccess === 0
      ) {
        this.countFailed = respCreateBagging.data.totalSttFailed;
        this.setFailed(true);
        BaggingController.setSttFailedUpdate(respCreateBagging.data.sttFailed);
        dataLayer(
          "create_bagging_failed",
          { error_message: "bagging gagal dibuat" },
          []
        );
      } else if (
        respCreateBagging.data.totalSttFailed > 0 &&
        respCreateBagging.data.totalSttSuccess > 0
      ) {
        this.countFailed = respCreateBagging.data.totalSttFailed;
        this.countSuccess = respCreateBagging.data.totalSttSuccess;
        BaggingController.setSttFailedUpdate(respCreateBagging.data.sttFailed);
        this.isPartial = true;
      } else {
        this.setOpenSuccess(true);
        dataLayer(
          "create_bagging_success",
          {
            bagging_number: respCreateBagging.data.bagCode,
            total_stt: this.totalStt,
            total_koli: this.totalPieces
          },
          []
        );
        if (this.withPrint) {
          MainAppController.showLoading();
          const printLabel = await BaggingController.getDetailBagging(
            respCreateBagging.data.bagId
          );
          if (printLabel) {
            const detailData = BaggingController.baggingDetail;
            const labelData = new BaggingData({
              bagId: BaggingController.responseCreate.data.bagId,
              bagCode: detailData.bagNumber,
              bagPartnerId: 0,
              bagPartnerCode: "",
              bagPartnerName: detailData.partnerName,
              bagOriginCityCode: detailData.originCity.slice(0, 3),
              bagOriginCityName: detailData.originCity.substring(
                6,
                detailData.originCity.length
              ),
              bagOriginTransitCityCode: "",
              bagOriginTransitCityName: "",
              bagDestinationCityCode: detailData.destinationCity.slice(0, 3),
              bagDestinationCityName: detailData.destinationCity.substring(
                6,
                detailData.destinationCity.length
              ),
              bagTotalStt: detailData.totalStt,
              bagTotalGrossWeight: detailData.totalGrossWeight,
              bagTotalVolumeWeight: detailData.totalVolumeWeight,
              bagTotalChargeableWeight: detailData.totalBagChargeableWeight,
              bagTotalPiece: detailData.totalPiece,
              bagCreatedAt: new Date(detailData.baggingDate).toISOString(),
              bagCreatedBy: 0,
              bagCreatedName: "",
              bagCustomGrossWeight: detailData.totalBagGrossWeight,
              bagCustomVolumeWeight: detailData.totalBagVolumeWeight,
              userType: ""
            });
            this.refs.print.printLabel(labelData);
            dataLayer(
              "create_bagging_success_label",
              {
                bagging_number: respCreateBagging.data.bagCode,
                total_stt: this.totalStt,
                total_koli: this.totalPieces
              },
              []
            );
          }
          MainAppController.closeLoading();
        }
      }
      this.sourceSttFromLocalStorage = [];
    }
  }

  createAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return BaggingController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    BaggingController.setOpenSuccess(value);
  }

  // failed for create
  get isFailed(): boolean {
    return BaggingController.isFailed;
  }
  setFailed(value: boolean) {
    BaggingController.setFailed(value);
  }

  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/bagging");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("bagging");
    removeDataLocalStorage("baggingGrossWeight");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }

  // error message
  onResetIsAlreadyExist() {
    this.form.sttNumber = "";
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();
  }

  numberOnly(value: any) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.+/g, ",")
      .replace(/,(\d{2})\d+/g, ",$1"); // keeping only the first two digits of any sequence of digits following a comma
  }

  onBlurWeight(value: any) {
    let newValue = Number.parseFloat(value.replace(',','.')).toFixed(2);
    const decimalDigit = Number(newValue.toString().split('.')[1]) * 10
    if (decimalDigit < 100 && decimalDigit != 0) { // check if decimal need te round up, hint: value need to less than 0.10 ex: 0.09
      const roundUpValue = Math.round(Number(newValue)) + 0.1
      newValue = roundUpValue.toFixed(2)
    }

    if (value === "0") { // no need to round up if value equal 0
      newValue = value
    }
    this.form.weight= newValue.replace('.',',')
  }

  get alertPopup() {
    const onDownload = () =>
      this.downloadCsvFailedStt({
        fileName: "bagging_stt_failed.csv",
        listStt: this.sttFailedUpdate
      });
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: this.form.isRTC
          ? "Pembuatan Bagging Siap di Kargo Berhasil"
          : this.$t("Pembuatan Bagging Berhasil !"),
        message: this.form.isRTC
          ? "Bagging berhasil dibuat dan akan terupdate di Siap di Kargo"
          : this.$t("Bagging berhasil dibuat."),
        image: "image-modal-success"
      };
    // if error typing not found
    if (this.isErrorTyping) {
      dataLayer(
        "input_STT/bagging_failed",
        {
          error_message: this.isScan
            ? "Scan No. STT Gagal"
            : "Tambah No. STT Gagal"
        },
        []
      );
      return {
        onClick: () => this.onResetIsAlreadyExist(),
        title: this.isScan ? this.$t("Scan No. STT Gagal") : this.$t("Tambah No. STT Gagal"),
        message: this.$t(this.errorSttNumber),
        image: "image-modal-failed"
      };
    }
    // if error typing not found
    if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: onDownload,
        title: this.$t("Pembuatan Bagging Berhasil !"),
        message:
         this.$t("Beberapa No. STT berhasil dibuat menjadi Bagging, namun terdapat kegagalan pada beberapa STT.<br />Silahkan download untuk mengetahui kesalahan."),
        image: "image-modal-warning"
      };
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: onDownload,
      title: this.$t("Pembuatan Bagging Gagal !"),
      message: this.$t("Bagging gagal dibuat."),
      image: "image-modal-failed"
    };
  }

  get isErrorGrossWeight() {
    return !Number((this.form.weight as string).replace(',','.'));
  }
  get isErrorDimension() {
    return (
      !this.totalBagVolumeWeight ||
      !this.form.width ||
      !this.form.length ||
      !this.form.height
    );
  }

  get isDisableProcess() {
    return (
      this.form.weight === "0" ||
      this.totalBagVolumeWeight === 0 ||
      this.form.width === 0 ||
      this.form.length === 0 ||
      this.form.height === 0
    );
  }

  get sttFailedUpdate() {
    return BaggingController.sttFailedUpdate;
  }

  // migrate from elexys
  get isMigrateFromElexys() {
    return isMigrateFromElexys();
  }
  get sttNumberGenesisTitle() {
    return `No. STT ${this.isMigrateFromElexys ? "Genesis" : ""}`;
  }

  get listSttProduct() {
    return this.dataSttToBeCreate.map((e: any) => {
      return e.sttProductType;
    });
  }

  get listSttCommodityGroup() {
    return this.dataSttToBeCreate.map((e: any) => {
      return e.sttCommodityGroupCode;
    });
  }

  filterDominanProductAndCommodity(products: [], commodities: []) {
    const product = this.countPopularityItems(products);
    const commodity = this.countPopularityItems(commodities);
    return {
      product: this.findMaxItems(product),
      commodity: this.findMaxItems(commodity)
    };
  }

  countPopularityItems(arr: any) {
    const data = arr.reduce((accumulator: any, item: any) => {
      return ((accumulator[item] = (accumulator[item] || 0) + 1), accumulator);
    }, {});
    return Object.entries(data).map((item: any[]) => ({
      name: item[0],
      total: item[1]
    }));
  }

  findMaxItems(totalDataByItem: any) {
    const findMax = totalDataByItem.reduce((prev: any, current: any) =>
      prev.total > current.total ? prev : current
    );
    const maxIndexLocation = totalDataByItem.reduce(
      (accumulator: any, currentValue: any, currentIndex: any) =>
        currentValue.total === findMax.total
          ? accumulator.concat(currentIndex)
          : accumulator,
      []
    );
    const result: any = [];
    maxIndexLocation.forEach((key: any) => {
      result.push(totalDataByItem[key].name);
    });
    return result.toString();
  }

  showNotesDestination = false;
  isDiffDestination = false;

  onConfirmDangerousGoods() {
    this.alertDangerousGoods = false;
    this.notification = true;

    // re focus
    const inputStt: any = this.refs.inputStt;
    inputStt.focus();
  }
}
