
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { capitalize } from "vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import debounce from "lodash/debounce";
import { AccountEntities } from "@/domain/entities/Account";
import { BaggingController } from "@/app/ui/controllers/BaggingController";
import {
  convertDecimal,
  convertDecimalWithComma,
  formatPrice
} from "@/app/infrastructures/misc/Utils";
import Print from "../../modules/print.vue";
import {
  BaggingData,
  SttResult,
  ResponseUpdate,
  SttNumberDetail
} from "@/domain/entities/Bagging";
import { LocationController } from "@/app/ui/controllers/LocationController";
import DownloadCsv from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import isFormatLiloBag from "@/app/infrastructures/misc/common-library/isFormatLiloBag";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    InputDimension,
    InputAdornment,
    Print
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.setPartial(false);
      this.setFailed(false);
      this.setOpenSuccess(false);
      next();
    }
  }
})
export default class UpdateFormBaggingManifest extends mixins(DownloadCsv) {
  alertDangerousGoods = false;
  formatPrice = formatPrice;
  pagination = {
    page: 1,
    limit: 50
  };
  refs: any = this.$refs;
  isErrorBagging = false;
  messageErrorNotification = "";
  notificationTimeout?: NodeJS.Timeout = undefined;
  mounted() {
    AccountController.checkAccountLocation();
    this.getDataDetail().then(() => {
      this.form.weight = (
        Math.floor(this.detailData.totalBagGrossWeight * 100) / 100
      )
        .toFixed(2)
        .replace(".", ",");
    });
    this.refs = this.$refs;
  }

  unmounted() {
    this.setPartial(false);
    this.setFailed(false);
    this.setOpenSuccess(false);
  }

  // fecth data for edit
  get id(): number {
    return Number(this.$route.params.id);
  }

  async getDataDetail() {
    return await BaggingController.getDetailBagging(this.id);
  }

  get detailData() {
    return BaggingController.baggingDetail;
  }

  get isLoading() {
    return BaggingController.isLoadingDetail;
  }
  get isError() {
    return BaggingController.isError;
  }
  get errorCause() {
    return BaggingController.errorCause;
  }

  closeModalErrorLoadBagging() {
    BaggingController.setError(false);
  }

  // get profile account
  get dataProfile(): AccountEntities {
    return AccountController.accountData;
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  // define bagging date
  get baggingDate() {
    return new Date(this.detailData.baggingDate);
  }

  // destination city
  fetchDestinationCity = debounce((search: string) => {
    LocationController.getCityList({
      search: search,
      status: "",
      page: 1,
      limit: 10
    });
  }, 250);

  get originCity() {
    const cityName = this.dataProfile.account_location.city_name;

    return `${this.dataProfile.account_location.city_code} - ${capitalize(
      cityName ? cityName.toLowerCase() : ""
    )}`;
  }

  get isInternal() {
    return this.dataProfile.account_type === "internal";
  }

  // add stt no
  errorSttNumber: any = "";
  errorSttPieces = "";

  isScan = false;
  sttNumberListAdded: Array<any> = [];
  addScanSttNumber() {
    this.isScan = true;
    this.addSttToUpdate();
  }

  async addSttNumber() {
    this.isScan = false;
    this.addSttToUpdate();
  }

  async addSttToUpdate() {
    this.isErrorBagging = false;
    this.isAlertUnpaid = false;
    this.errorSttNumber = "";
    let payload: any = {
      sttNumber: this.form.sttNumber,
      callback: this.addStt
    };
    const isCheckTrackingSttFirst = this.checkSttorBagFormatNumber(
      this.form.sttNumber.trim()
    ); // this is for checking if it need to use tracking stt endpoint or not
    const isSttFromLilo = this.checkSttFromLilo(this.form.sttNumber.trim());

    payload = this.isInternal
      ? { ...payload, partnerId: this.detailData.partnerId }
      : payload;

    await this.checkTrackingSttFirst(
      isCheckTrackingSttFirst,
      payload,
      isSttFromLilo
    );
  }

  async checkTrackingSttFirst(
    isCheckTrackingSttFirst: any,
    payload: any,
    isSttFromLilo: any
  ) {
    if (!isCheckTrackingSttFirst) {
      const numberExceptSttorBag = this.form.sttNumber;
      await TrackingController.trackStt({
        sttNo: this.form.sttNumber.trim(),
        isGetSla: false,
        isSecondCall: false,
        cache: true
      }).then(async isSuccess => {
        if (isSuccess) {
          payload.sttNumber = this.sttDetail.sttNo;
        }
        await BaggingController.getDetailSttNumber(payload).then(() => {
          if (!isSttFromLilo && isSuccess) {
            this.detailData.sttResult.map(value => {
              if (
                value.sttNo.toLowerCase() ==
                this.sttDetail.sttNo.toLocaleLowerCase()
              ) {
                value.sttNoRefExternal = numberExceptSttorBag; // this for put no refrence on table if scan except stt or bag
              }
              return value;
            });
          }
          return;
        });
      });
      return;
    }
    await BaggingController.getDetailSttNumber(payload);
  }

  checkSttorBagFormatNumber(value: string) {
    const sttPatern = /^\d{2}[a-z]{2}/; // check for stt number
    const bagPattern = /^[a-z]{3}-\d+$/; // check for bag number
    return (
      sttPatern.test(value.toLocaleLowerCase()) ||
      bagPattern.test(value.toLocaleLowerCase())
    );
  }

  checkSttFromLilo(value: string) {
    return isFormatLiloBag(value)
  }

  addStt(isGetSuccess: any) {
    if (isGetSuccess) {
      const checkErrorUpdate = isGetSuccess.find(
        (item: any) => item.isAllowUpdateStatus === false
      );
      const checkEligibleUpdate = isGetSuccess.map(
        (item: any) => item.isAllowUpdateStatus !== false
      );
      const checkErrorSttNeedAssessment = isGetSuccess.find(
      (item: any) =>
        item.stt.sttAssessmentStatus === "waiting" ||
        item.stt.sttAssessmentStatus === "rejected"
    );
      const isNotEligible = (val: any) => val === false;
      if (checkErrorSttNeedAssessment) {
        this.isErrorBagging = true;
        playNotification("error");
        this.isAlertUnpaid = true;
        this.errorSttNumber =
          "Gagal scan karena adjustment STT belum disetujui tim Lion Parcel, tunggu dihubungi CS.";
        this.messageErrorNotification =
          "Silahkan menunggu CS follow up untuk konfirmasi adjust ulang STT. ";

        clearTimeout(this.notificationTimeout as NodeJS.Timeout);
        this.notificationTimeout = setTimeout(() => {
          this.notification = false;
        }, 15000);
        for (const detailSTT of isGetSuccess) {
          this.onFailedSttResults(detailSTT);
        }
      } else if (checkEligibleUpdate.every(isNotEligible)) {
        playNotification("error");
        this.isErrorTyping = true;
        this.errorSttNumber = checkErrorUpdate.errorMessage;
      } else {
        for (const detailSTT of isGetSuccess) {
          this.onValidateSttResults(detailSTT);
        }
      }
    }
  }

  onValidateSttResults(detailSTT: any) {
    const indexCurrentSttNumber = this.detailData.sttResult.findIndex(
      data => data.sttNo === detailSTT.stt.sttNo
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.errorSttNumber = this.$t("No. STT sudah di input");
      this.isErrorTyping = true;
      return;
    } else if (!detailSTT.isPaid) {
      this.isUnpaid = true;
      this.isAlertUnpaid = true;
      playNotification("error");
    } else {
      const newSttNo = new SttResult({
        sttId: detailSTT.stt.sttId,
        sttNo: detailSTT.stt.sttNo,
        sttProductType: detailSTT.stt.sttProductType,
        sttTotalPiece: detailSTT.stt.sttTotalPiece,
        sttDestinationCityId: detailSTT.stt.sttDestinationCityId,
        sttOriginCityId: detailSTT.stt.sttOriginCityId || "",
        sttDestinationCityName: detailSTT.stt.sttDestinationCityName,
        sttWoodPacking: detailSTT.stt.sttWoodPacking,
        sttCommodityCode: detailSTT.stt.sttCommodityCode,
        sttCommodityName: detailSTT.stt.sttCommodityName,
        sttGrossWeight: detailSTT.stt.sttGrossWeight,
        sttVolumeWeight: detailSTT.stt.sttVolumeWeight,
        sttChargeableWeight: detailSTT.stt.sttChargeableWeight,
        sttCodAmount: detailSTT.stt.sttCodAmount,
        sttLastStatusId: detailSTT.stt.sttLastStatusId,
        bookedAt: detailSTT.stt.bookedAt,
        sttPiece: detailSTT.stt.piece,
        sttNumberElexys: detailSTT.stt.sttNumberElexys,
        isDangerousGoods: detailSTT.stt.isDangerousGoods
      });
      this.detailData.sttResult.push(newSttNo);
      this.sttNumberListAdded.push({
        // eslint-disable-next-line @typescript-eslint/camelcase
        stt_no: detailSTT.stt.sttNo,
        action: "add"
      });
      playNotification("success");
      this.autoCalculateBagGrossWeight(); // add auto calculate bag gross weight when success add stt
    }
    this.detailSttNotification = {
      sttNumber: detailSTT.stt.sttNo,
      destinationCity: detailSTT.stt.sttDestinationCityName,
      product: detailSTT.stt.sttProductType,
      woodpacking: detailSTT.stt.sttWoodPacking === "Yes",
      commodity: detailSTT.stt.sttCommodityName,
      totalPieces: detailSTT.stt.sttTotalPiece,
      codAmount: detailSTT.stt.sttCodAmount,
      sttNumberElexys: detailSTT.stt.sttNumberElexys,
      isDangerousGoods: detailSTT.stt.isDangerousGoods,
      cityId: detailSTT.stt.sttDestinationCityId,
      cityName: detailSTT.stt.sttDestinationCityName
    };
    this.form.sttNumber = "";
    this.form.cacheDestinationCity = this.form.destinationCity;
    this.fetchDestinationCity("");

    if (detailSTT.stt.isDangerousGoods) {
      this.alertDangerousGoods = true;
    } else {
      this.notification = true;
    }

    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();

    if (!this.isUnpaid) {
      clearTimeout(this.notificationTimeout as NodeJS.Timeout);
      this.notificationTimeout = setTimeout(() => {
        this.notification = false;
      }, 15000);
    }
  }

  onFailedSttResults(detailSTT: any) {
    this.detailSttNotification = {
      sttNumber: detailSTT.stt.sttNo,
      destinationCity: `${detailSTT.stt.sttDestinationCityId} - ${detailSTT.stt.sttDestinationCityName}`,
    };

    this.notification = true;
  }

  onCloseNotification() {
    this.isUnpaid = false;
    this.isAlertUnpaid = false;
    this.errorSttNumber = "";
  }

  get sttDetail() {
    return TrackingController.trackingSttData.sttDetail;
  }

  get isErrorAddSttNo() {
    return BaggingController.errorDetailSttNo;
  }

  get errorCauseAddSttNo() {
    return BaggingController.errorCauseDetailSttNo;
  }

  closModalErrorAddsttNo() {
    BaggingController.setErrorDetailSttNo(false);
  }

  // delete stt no
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  sttNumberListTrash: Array<any> = [];
  sttNumberDeletedPresent: Array<any> = [];
  onDeleteSttNumber() {
    this.sttNumberToDelete = this.sttNumberToDelete.split("#")[0];
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      const indexSttNumber = this.detailData.sttResult.findIndex(
        (data: any) => {
          return (
            data.sttNo === this.sttNumberToDelete ||
            data.sttNumberElexys === this.sttNumberToDelete
          );
        }
      );

      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = this.$t(
          "No. STT tidak ditemukan/sudah terhapus"
        );
      } else if (this.detailData.sttResult.length <= 1) {
        this.errorSttNumberDelete = this.$t(
          "No. STT tidak dapat dihapus. Minimal barang pengiriman bagging adalah 1 STT."
        );
        return;
      } else {
        const indexSttNumberAdded = this.sttNumberListAdded.findIndex(
          (item: any) => {
            return item.stt_no === this.sttNumberToDelete;
          }
        );
        if (indexSttNumberAdded >= 0) {
          this.sttNumberListAdded.splice(indexSttNumberAdded, 1);
        } else {
          this.sttNumberListTrash.push({
            // eslint-disable-next-line @typescript-eslint/camelcase
            stt_no: this.detailData.sttResult[indexSttNumber].sttNo,
            action: "remove"
          });
        }
        this.detailData.sttResult.splice(indexSttNumber, 1);
        this.autoCalculateBagGrossWeight(); // add auto calculate bag gross weight when success remove stt
        this.sttNumberDeletedPresent.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    this.isUnpaid = false;
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt?.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberDeletedPresent = [];
    this.isUnpaid = false;
  }

  autoCalculateBagGrossWeight() {
    this.totalBagGrossWeight = this.detailData.sttResult.reduce(
      (total, item: SttResult) => {
        return total + item.sttGrossWeight;
      },
      0
    );
    this.form.weight = (Math.ceil(this.totalBagGrossWeight * 100) / 100)
      .toFixed(2)
      .replace(".", ",");
  }

  numberOnly(value: any) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.+/g, ",")
      .replace(/,(\d{2})\d+/g, ",$1"); // keeping only the first two digits of any sequence of digits following a comma
  }

  onBlurWeight(value: any) {
    let newValue = Number.parseFloat(value.replace(",", ".")).toFixed(2);
    const decimalDigit = Number(newValue.toString().split(".")[1]) * 10;
    if (decimalDigit < 100 && decimalDigit != 0) {
      // check if decimal need te round up, hint: value need to less than 0.10 ex: 0.09
      const roundUpValue = Math.round(Number(newValue)) + 0.1;
      newValue = roundUpValue.toFixed(2);
    }


    if (value === "0") {
      // no need to round up if value equal 0
      newValue = value;
    }
    this.form.weight = newValue.replace(".", ",");
  }

  get totalStt() {
    return this.detailData.sttResult.length;
  }
  get totalPieces() {
    return this.detailData.sttResult.reduce((a: any, b: any) => {
      return a + b.sttPiece.length;
    }, 0);
  }
  get totalGrossWeight() {
    return this.detailData.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.detailData.totalVolumeWeight;
  }
  get totalBagGrossWeight() {
    return this.detailData.totalBagGrossWeight;
  }
  set totalBagGrossWeight(newValue) {
    this.detailData.totalBagGrossWeight = newValue;
  }
  get totalBagChargeableWeight() {
    const weight = Number((this.form.weight as string).replace(",", "."));
    return weight > this.detailData.totalBagVolumeWeight
      ? this.isValueDecimal(weight)
      : this.isValueDecimal(+this.detailData.totalBagVolumeWeight);
  }

  get totalDangerousGoods() {
    return this.detailData.sttResult.filter(stt => stt.isDangerousGoods).length;
  }

  get isErrorGrossWeight() {
    return !Number((this.form.weight as string).replace(",", "."));
  }
  get isErrorDimension() {
    return !this.form.width || !this.form.length || !this.form.height;
  }
  get isFormValid() {
    return (
      this.form.weight !== "0" &&
      this.form.height !== 0 &&
      this.form.length !== 0 &&
      this.form.width !== 0
    );
  }

  // gross volume weight
  onChangeDimension() {
    const total: any =
      (this.form.length * this.form.width * this.form.height) / 4000;
    this.detailData.totalBagVolumeWeight = Math.ceil(total);
  }

  // form model
  form = {
    destinationCity: "",
    cacheDestinationCity: "",
    sttNumber: "",
    length: this.detailData.bagCustomLength,
    width: this.detailData.bagCustomWidth,
    height: this.detailData.bagCustomHeight,
    weight: "0"
  };

  isUnpaid = false;
  isAlertUnpaid = false;
  get isDisableAddSttPieces() {
    return !this.form.sttNumber;
  }

  // panel pickup
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op?.toggle(event);
  }

  // notification handler
  notification = false;
  detailSttNotification: any = null;

  // table
  get columns() {
    const column = [
      {
        name: "No.",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${item.sttNo}</div>`;
        }
      },
      {
        name: this.$t("No. Referensi"),
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${item.sttNoRefExternal}</div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-2/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300 flex">
                    <img src="${require(`@/app/ui/assets/svg/product-logo/${item.sttProductType.toLowerCase()}-active.svg`)}" />
                </div>`;
        }
      },
      {
        name: this.$t("Komoditas"),
        styleHead: "w-3/20 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300">${item.sttCommodityName}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: "Kota tujuan",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttDestinationCityId}
                  </div>
                </div>`;
        }
      },
      {
        name: "Dangerous Goods",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<img src="${require(`@/app/ui/assets/svg/${
            item.isDangerousGoods ? "icon-check-circle-red" : "icon-close-black"
          }.svg`)}" />`;
        }
      },
    ];

    if (!this.detailData.isPaid) {
      column.push({
        name: this.$t("Pembayaran"),
        styleHead: "w-48 text-left text-black-lp-300 whitespace-no-wrap",
        render: (item: SttNumberDetail) => {
          return `<div class='text-left ${
            item?.paymentStatus === "unpaid"
              ? "bg-yellow-lp-500"
              : "bg-green-lp-700"
          } text-white font-medium font-montserrat rounded-md px-3 py-1 whitespace-no-wrap text-12px xxl:text-14px'>${
            item?.paymentStatus === "unpaid"
              ? this.$t("Kurang Bayar")
              : this.$t("Sudah Lunas")
          }</div>`;
        }
      });
    }
    return column;
  }

  // error handling
  isErrorTyping = false;
  isPartial = false;
  isFailed = false;

  setPartial(value: boolean) {
    this.isPartial = value;
  }

  setFailed(value: boolean) {
    this.isFailed = value;
  }

  setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  onClosePartial() {
    this.isPartial = false;
  }

  onResetIsAlreadyExist() {
    this.form.sttNumber = "";
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();
  }

  get alertPopup() {
    const onDownload = () =>
      this.downloadCsvFailedStt({
        fileName: "bagging_update_stt_failed.csv",
        listStt: this.sttFailedList
      });
    // if success
    if (this.openSuccess) {
      return {
        onClick: () => this.onCloseSuccess(),
        title: this.$t("Perubahan Berhasil Disimpan !"),
        message: this.$t("bagging.successEditBagTitle", {
          bagNo: this.detailData.bagVendorNo
            ? this.detailData.bagVendorNo
            : this.detailData.bagNumber
        }),
        image: "image-modal-success"
      };
    }

    // // if error typing not found
    if (this.isErrorTyping) {
      return {
        onClick: () => this.onResetIsAlreadyExist(),
        title: this.$t("Tambah No. STT Gagal"),
        message: this.$t(this.errorSttNumber),
        image: "image-modal-failed"
      };
    }

    // if error typing not found
    if (this.isPartial) {
      return {
        onClick: () => this.onClosePartial(),
        onDownload: onDownload,
        title: this.$t("Perubahan Status Berhasil !"),
        message: this.$t(
          "Beberapa status STT berhasil diupdate menjadi Bagging, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan."
        ),
        image: "image-modal-warning"
      };
    }

    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: onDownload,
      title: this.$t("Perubahan Status Gagal !"),
      message: this.$t("Perubahan status Bagging gagal dibuat."),
      image: "image-modal-failed"
    };
  }

  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  sttFailedList: Array<string> = [];

  onDelaySave = debounce((id: number) => {
    this.onSave(id);
  }, 250);

  onShowLoadingSave() {
    this.handleConfirmationSave(false);
    MainAppController.showLoading();
    this.onSave(this.id);
  }

  async onSave(id: number) {
    const sttNos: Array<any> = this.sttNumberListAdded.concat(
      this.sttNumberListTrash
    );
    let payload: any = {
      bagId: id,
      customGrossWeight: Number((this.form.weight as string).replace(",", ".")),
      customLength: this.form.length,
      customWidth: this.form.width,
      customHeight: this.form.height,
      bagSttNo: sttNos
    };
    payload = this.isInternal
      ? { ...payload, partnerId: this.detailData.partnerId }
      : payload;
    const update: ResponseUpdate = await BaggingController.updateBagging(
      payload
    );
    if (update) {
      if (this.withPrint) {
        MainAppController.showLoading();
        const printLabel = await BaggingController.getDetailBagging(
          BaggingController.baggingId
        );
        if (printLabel) {
          const detailData = BaggingController.baggingDetail;
          const labelData = new BaggingData({
            bagId: BaggingController.baggingId,
            bagCode: detailData.bagNumber,
            bagPartnerId: 0,
            bagPartnerCode: "",
            bagPartnerName: detailData.partnerName,
            bagOriginCityCode: detailData.originCity.slice(0, 3),
            bagOriginCityName: detailData.originCity.substring(
              6,
              detailData.originCity.length
            ),
            bagOriginTransitCityCode: "",
            bagOriginTransitCityName: "",
            bagDestinationCityCode: detailData.destinationCity.slice(0, 3),
            bagDestinationCityName: detailData.destinationCity.substring(
              6,
              detailData.destinationCity.length
            ),
            bagTotalStt: this.totalStt,
            bagTotalGrossWeight: this.totalGrossWeight,
            bagTotalVolumeWeight: this.totalVolumeWeight,
            bagTotalChargeableWeight: this.detailData.totalBagChargeableWeight,
            bagTotalPiece: this.totalPieces,
            bagCreatedAt: new Date(detailData.baggingDate).toISOString(),
            bagCreatedBy: 0,
            bagCreatedName: "",
            bagCustomGrossWeight: this.totalBagGrossWeight,
            bagCustomVolumeWeight: this.detailData.totalBagVolumeWeight,
            userType: ""
          });
          this.refs.print.printLabel(labelData, detailData.bagVendorNo);
        }
        MainAppController.closeLoading();
      }

      if (update.totalSttSuccess > 0 && update.totalSttFailed > 0) {
        this.countFailed = update.totalSttSuccess;
        this.countSuccess = update.totalSttFailed;
        this.isPartial = true;
      } else if (update.totalSttFailed > 0) {
        this.sttFailedList = update.sttFailed;
        this.countFailed = update.totalSttFailed;
        this.isFailed = true;
      } else {
        this.openSuccess = true;
      }
    } else {
      this.closeModalErrorUpdate();
    }
  }

  closeModalErrorUpdate() {
    this.openConfirmationSave = false;
    BaggingController.setErrorUpdate(false);
  }

  createAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // handle route leave and modal
  // navigation
  openSuccess = false;
  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push(`${this.$route.matched[0].path}/${this.id}`);
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }

  onConfirmDangerousGoods() {
    this.alertDangerousGoods = false;
    this.notification = true;

    // re focus
    const inputStt: any = this.refs.inputStt;
    inputStt.focus();
  }
}
